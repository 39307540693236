const mainColors = {
    grey1: '#262626',
    grey2: '#828282',
    grey3: '#BDBDBD',
    grey4: '#2F2F2F',
    grey5: '#E0E0E0',
    dark1: '#181818',
    black1: '#000000',
    white1: '#FFFFFF',
    white2: '#F2F2F2',
    white3: '#E0E0E0',
    green1: '#39AC37',
}

export const colors = {
    background: {
        primary: mainColors.dark1,
        secondary: mainColors.grey1,
        tertiary: mainColors.white1,
        quaternary: mainColors.white2,
        quinary: mainColors.green1
    },
    text: {
        primary: mainColors.white1,
        secondary: mainColors.white2,
        tertiary: mainColors.grey3,
        quaternary: mainColors.grey2,
        quinary: mainColors.grey5,
        senary: mainColors.dark1
    },
    border: {
        primary: mainColors.grey2,
        secondary: mainColors.grey4,
        tertiary: mainColors.grey5
    },
    icon: {
        primary: mainColors.grey2,
        secondary: mainColors.grey3,
        tertiary: mainColors.dark1,
        quaternary: mainColors.green1,
    }

}