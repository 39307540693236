import { colors } from "../../../utils"

export const stylesDark = {
    container: {
        left: '0px',
        position: 'fixed',
        right: '0px',
        top: '0px',
        zIndex: 1000,
    },
    img: {
        maxWidth: '22px',
        maxHeight: '24px',
    },
    navbar: {
        backgroundColor: colors.background.secondary,
        paddingLeft: '20px',
        height: '60px',
    },
    brand: {
        color: '#FFFFFF',
        fontWeight: 'bold'
    },
    title: {
        color: colors.text.tertiary,
        fontWeight: '500',
        fontSize: '14px'
    }
}

export const stylesLight = {
    container: {
        left: '0px',
        position: 'fixed',
        right: '0px',
        top: '0px',
        zIndex: 1000,
    },
    img: {
        maxWidth: '22px',
        maxHeight: '24px',
    },
    navbar: {
        backgroundColor: colors.background.tertiary,
        paddingLeft: '20px',
        height: '60px',
        borderBottom: '1px solid #E0E0E0',
    },
    brand: {
        color: colors.text.senary,
        fontWeight: 'bold'
    },
    title: {
        color: colors.text.tertiary,
        fontWeight: '500',
        fontSize: '14px',
        alignSelf: 'center' 
    },
    btnIcon: {
        border: '1px solid #BDBDBD',
        color: colors.text.tertiary,
        fontWeight: '500',
        fontSize: '14px',
        alignSelf: 'center' 
    }
}