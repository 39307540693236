import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { Home, Email, Chart, OnBoardingSecond, OnBoardingThird, OnBoardingFourth } from "../pages";

const AppRouter = () => {
  return (
    <Router>
      {/* <Navbar /> */}
      {/* <Sidebar /> */}
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/second' component={OnBoardingSecond} />
        <Route path='/third' component={OnBoardingThird} />
        <Route path='/fourth' component={OnBoardingFourth} />
        <Route path='/email' component={Email} />
        <Route path='/chart' component={Chart} />
      </Switch>
    </Router>
  );
}

export default AppRouter