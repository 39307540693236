const initialState = {
  samples: {
    'targetPengguna': ['Emak - emak', 'Anak kos'],
    'solusi': ['Shopeefy', 'Bukalapak'],
    'masalah': ['Shopefy', 'Lapakku'],
    'jenisProduk': ['High Coverting Headlines', 'Copyfast', 'Sketch', 'Sports'],
    'productName': ['Lamborghini', 'Burgerking', 'Le Minerale', 'Kompas'],
    'periode': ['Bulan Ini', 'Akhir Minggu', 'Hari Ini'],
    'tokohAhli': ['Emak-emak', 'Anak Kos'],
    'number': ['86'],
    'produk': ['Shopeefy', 'Lapakku'],
    'hasil': ['Irit', 'Cepat', 'Aman', 'Cerdas']
  },
  sidebars: {
    product: ''
  }
}

export const HomeReducer = (state = initialState, action) => {
  if (action.type === 'SET_FORM') {
    return {
      ...state,
      form: {
        ...state.form,
        [action.inputType]: action.inputValue,
      },
    };
  }
  return state;
};