import { colors } from "../../utils";

export const darkTheme = {
  page: {
    backgroundColor: colors.background.primary,
  },
  title: {
    fontWeight: '500',
    // fontSize: '20px',
    color: colors.text.primary
  },
  button: {
    color: '#FFFFFF',
    fontSize: '12px',
  },
  container: {
    zIndex: 999,
    overflowY: 'auto',
    height: '100%',
    backgroundColor: colors.background.primary,
    borderRight: `1px solid #2F2F2F`,
    borderBottom: '1px solid #2F2F2F',
  },
  containerSecond: {
    zIndex: 999,
    overflowY: 'auto',
    height: '100%',
    backgroundColor: colors.background.secondary,
    borderRight: '1px solid #2F2F2F',
    borderBottom: '1px solid #2F2F2F',
  },
  sidebar: {
    paddingTop: '50px',
  },
  label: {
    fontSize: '12px',
    color: colors.text.tertiary,
    fontWeight: '500',
    fontFamily: 'Montserrat'
  },
  iconCode: {
    marginRight: '7px'
  },
  icon: {
    marginLeft: 'auto'
  },
  formSelect: {
    width: '100%',
    marginTop: '15px',
    borderColor: colors.border.secondary,
    borderWidth: '1px',
    backgroundColor: colors.background.primary,
    color: colors.text.quinary
  },
  formSelectSecondary: {
    width: '100%',
    marginTop: '15px',
    borderColor: colors.border.secondary,
    borderWidth: '1px',
    backgroundColor: colors.background.secondary,
    color: colors.text.quinary
  },
  input: {
    backgroundColor: colors.background.secondary,
    color: colors.text.quaternary,
    fontSize: '14px',
    fontWeight: '400',
    borderWidth: 0,
  },
  inputSecond: {
    backgroundColor: colors.background.primary,
    color: colors.text.quaternary,
    fontSize: '14px',
    fontWeight: '400',
    borderWidth: 0,
  },
  content: {
    backgroundColor: colors.background.primary,
    width: '100%',
    height: '100%',
  },
  inputUrutkan: {
    backgroundColor: colors.background.primary, 
    border: 0, 
    color:  colors.text.tertiary,
    // fontSize: '14px',
  },
  buttonUrutan: {
    borderRadius: 0, 
    borderTopLeftRadius: '2px', 
    borderBottomLeftRadius: '2px', 
    borderColor: colors.border.secondary
  },
  buttonExample: {
    color: colors.text.tertiary,
    fontSize: '12px',
    fontWeight: '500'
  },
}

export const lightTheme = {
  page: {
    backgroundColor: colors.background.tertiary,
    borderWidth: 1,
    borderColor: 'red',
  },
  title: {
    fontWeight: '700',
    // fontSize: '24px',
    color: colors.text.senary
  },
  button: {
    color: '#FFFFFF',
    fontSize: '12px',
  },
  container: {
    zIndex: 999,
    overflowY: 'auto',
    height: '100%',
    marginBottom: 200,
    backgroundColor: colors.background.tertiary,
    borderRight: '1px solid #E0E0E0',
    borderBottom: '1px solid #E0E0E0',
  },
  containerSecond: {
    zIndex: 999,
    overflowY: 'auto',
    height: '100%',
    backgroundColor: colors.background.quaternary,
    borderRight: '1px solid #E0E0E0',
    borderBottom: '1px solid #E0E0E0',
  },
  sidebar: {
    paddingTop: '50px',
  },
  label: {
    fontSize: '12px',
    color: colors.text.quaternary,
    fontWeight: '500',
    fontFamily: 'Montserrat'
  },
  iconCode: {
    marginRight: '7px'
  },
  icon: {
    marginLeft: 'auto'
  },
  formSelect: {
    width: '100%',
    marginTop: '15px',
    borderColor: colors.border.tertiary,
    borderWidth: '1px',
    backgroundColor: colors.background.tertiary,
    color: colors.text.tertiary
  },
  formSelectSecondary: {
    width: '100%',
    marginTop: '15px',
    borderColor: colors.border.tertiary,
    borderWidth: '1px',
    backgroundColor: colors.background.tertiary,
    color: colors.text.tertiary,
  },
  input: {
    backgroundColor: colors.background.quaternary,
    color: colors.text.quaternary,
    fontSize: '14px',
    fontWeight: '400',
    borderWidth: 0,
  },
  content: {
    backgroundColor: colors.background.tertiary,
    width: '100%',
    height: '100%',
  },
  inputUrutkan: {
    backgroundColor: colors.background.tertiary, 
    border: 0, 
    color:  colors.text.senary,
    // fontSize: '14px',
  },
  buttonExample: {
    color: colors.text.tertiary,
    fontSize: '12px',
    fontWeight: '500'
  }
}