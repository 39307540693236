const initialStateLogin = {
  form: {
    email: '',
    password: ''
  },
  isLogin: true
}

export const LoginReducer = (state = initialStateLogin, action) => {
  if (action.type === 'SET_FORM') {
    return {
      ...state,
      form: {
        ...state.form,
        [action.inputType]: action.inputValue,
      },
    };
  }
  return state;
};