import { combineReducers } from 'redux'
import {RegisterReducer} from './Register'
import {LoginReducer} from './Login'
import {HomeReducer} from './Home'
import {OnBoardingReducer} from './OnBoarding'

// const initialStateOnBoarding = {
//   form: {
//     jenisProduk: '',
//     targetPengguna: '',
//     hasil: '',
//     problem: '',
//     solusi: '',
//     tokohAhli: '',
//     produk: '',
//   },
//   datas: [
//     {
//       id: 1,
//       label: 'What is your product?',
//       placeholder: 'Copyfast, Skecth',
//     },
//     {
//       id: 2,
//       label: 'What does your audience want?',
//       placeholder: 'Higher Convension Rate, Bigger Muscles',
//     },
//     {
//       id: 3,
//       label: 'What solution do you offer?',
//       placeholder: 'Copyfast, Headlime',
//     },
//     {
//       id: 4,
//       label: 'What is your product?',
//       placeholder: 'Copyfast, Adobe',
//     },
//     {
//       id: 5,
//       label: 'What does your audience want?',
//       placeholder: 'Higher Convention Rate, Bigger Muscles',
//     },
//     {
//       id: 6,
//       label: 'What solution do you offer',
//       placeholder: 'Headlime, Sketch',
//     },
//   ]
// }

// const initialStateRegister = {
//   form: {
//     fullName: '',
//     email: '',
//     password: ''
//   },
// }

// const initialStateLogin = {
//   form: {
//     email: '',
//     password: ''
//   },
//   isLogin: true
// }

// const initialStateHome = {
//   samples: {
//     'targetPengguna': ['Indiehackers', 'Doctors', 'Athlets', 'Fathers'],
//     'solusi': ['Wix', 'Elementor'],
//     'masalah': ['Ngoding', 'Membuat Website'],
//     'jenisProduk': ['High Coverting Headlines', 'Copyfast', 'Sketch', 'Sports'],
//     'productName': ['Lamborghini', 'Burgerking', 'Le Minerale', 'Kompas'],
//     'periode': ['Bulan Ini', 'Akhir Minggu', 'Hari Ini'],
//     'tokohAhli': ['Programmer', 'Freelancer', 'Developer'],
//     'number': ['86'],
//     'produk': ['Elementor', 'Wix'],
//     'hasil': ['Eat well', 'Drink well', 'Are healthy', 'Smart']
//   }
// }

// const OnBoardingReducer = (state = initialStateOnBoarding, action) => {
//   if(action.type === 'SET_FORM') {
//     return {
//       ...state,
//       form: {
//         ...state.form,
//         [action.inputType]: action.inputValue,
//       },
//     }
//   }
//   return state
// }

// const RegisterReducer = (state = initialStateRegister, action) => {
//   if (action.type === 'SET_FORM') {
//     return {
//       ...state,
//       form: {
//         ...state.form,
//         [action.inputType]: action.inputValue,
//       }
//     }
//   }
//   return state;
// }

// const LoginReducer = (state = initialStateLogin, action) => {
//   if (action.type === 'SET_FORM') {
//     return {
//       ...state,
//       form: {
//         ...state.form,
//         [action.inputType]: action.inputValue,
//       },
//     };
//   }
//   return state;
// };

// const HomeReducer = (state = initialStateHome, action) => {
//   return state
// }

const reducer = combineReducers({
  RegisterReducer,
  LoginReducer,
  HomeReducer,
  OnBoardingReducer
})

export default reducer