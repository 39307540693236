import React, { useState } from "react"
import {
  FormGroup, Label, Input
} from 'reactstrap';
import { colors } from "../../../utils";

const Sidebar = () => {
  const [audience, setAudience] = useState('{Audience}')
  const [solution, setSolution] = useState('{Solution}')
  const [problem, setProblem] = useState('{Problem}')
  const [product, setProduct] = useState('{Product}')
  const [desireOutcome, setDesireOutcome] = useState('{Desire Outcome}')
  const [period, setPeriod] = useState('{period}')
  const [productName, setProductName] = useState('{Product Name}')

  const sideBars = [
    {
      id: 1,
      label: 'Audience',
      name: 'audience',
      placeholder: 'Designer, Indiehackers',
      onChange: setAudience,
      value: audience,
    },
    {
      id: 2,
      label: 'Solution',
      name: 'solution',
      placeholder: 'Healthy Food, Faster Site',
      onChange: setSolution,
      value: solution,
    },
    {
      id: 3,
      label: 'Problem',
      name: 'problem',
      placeholder: 'Low Conversion Rate, Boring Copy',
      onChange: setProblem,
      value: problem,
    },
    {
      id: 4,
      label: 'Product',
      name: 'product',
      placeholder: 'Headlines That Convert, Design Tool',
      onChange: setProduct,
      value: product,
    },
    {
      id: 5,
      label: 'Desire Outcome',
      name: 'desire',
      placeholder: 'Convert Visitors Into Leads',
      onChange: setDesireOutcome,
      value: desireOutcome,
    },
    {
      id: 6,
      label: 'Period',
      name: 'period',
      placeholder: 'X Months, Last Week',
      onChange: setPeriod,
      value: period
    },
    {
      id: 7,
      label: 'Product Name',
      name: 'product',
      placeholder: 'Headlime, Landingfolio',
      onChange: setProductName,
      value: productName
    },
  ]

  const samples = {
    'audience': ['Students', 'Doctors', 'Athlets', 'Teachers'],
    'solution': ['Buy', 'Drink', 'Read', 'Sport'],
    'problem': ['Thirst', 'Hunger', 'Reading', 'Health'],
    'product': ['Drink', 'Food', 'Healthy', 'Sport'],
    'productName': ['Lamborghini', 'Burgerking', 'Le Minerale', 'Kompas'],
    'desireOutcome': ['Eat well', 'Drink well', 'Are healthy', 'Smart']
  }

  const onHandleSample = () => {
    console.log('random sample')
    const sampleAudience = samples.audience
    const sampleSolution = samples.solution
    const sampleProblem = samples.problem
    const sampleProduct = samples.product
    const sampleDesireOutcome = samples.desireOutcome
    const samplePeriod = samples.period
    const sampleProductName = samples.productName
    setAudience(sampleAudience[Math.floor(Math.random() * sampleAudience.length)])
    setSolution(sampleSolution[Math.floor(Math.random() * sampleSolution.length)])
    setProblem(sampleProblem[Math.floor(Math.random() * sampleProblem.length)])
    setProduct(sampleProduct[Math.floor(Math.random() * sampleProduct.length)])
    setDesireOutcome(sampleDesireOutcome[Math.floor(Math.random() * sampleDesireOutcome.length)])
    // setPeriod(samplePeriod[Math.floor(Math.random() * samplePeriod.length)])
    setProductName(sampleProductName[Math.floor(Math.random() * sampleProductName.length)])
  }

  return (
    <div
      class="sidebar col-3 position-fixed"
      style={styles.container}>
      <div class="p-2" style={styles.sidebar}>
        <FormGroup>
          <Label for="exampleSelect" style={styles.label}>Category</Label>
          <Input type="select" name="select" id="exampleSelect" style={styles.input}>
            <option>All categories</option>
            <option>Ad</option>
            <option>Email</option>
            <option>Article</option>
            <option>Landing Page</option>
          </Input>
        </FormGroup>
        <div className="d-flex justify-content-between align-items-center pb-1">
          <Label for="exampleSelect" style={styles.label}>Variable</Label>
          <button className="btn btn-outline-light btn-sm" style={styles.buttonExample} onClick={onHandleSample}>Use Example</button>
        </div>
        <div class="mb-4 flex flex-col">
          {sideBars.map((item, index) => {
            return (
              <FormGroup key={index}>
                <Label for="exampleEmail" style={styles.label}>{item.label}</Label>
                <Input type="text" name={item.name} id={item.id} placeholder={item.placeholder} value={item.value}
                  onChange={(e) => item.onChange(e.target.value)} style={styles.input} />
              </FormGroup>
            )
          })}
        </div>
      </div>
    </div>
  )
}

const styles = {
  container: {
    zIndex: 999,
    overflowY: 'auto',
    height: '100%',
    marginBottom: 200,
    backgroundColor: colors.background.primary
  },
  sidebar: {
    marginBottom: '40%'
  },
  label: {
    color: 'red',
    fontWeight: '500',
    fontFamily: 'Montserrat'
  },
  input: {
    backgroundColor: '#252525',
    borderColor: '#515151',
    borderWidth: 1,
  },
  card: {
    backgroundColor: '#252525',
    height: '80%',
    width: '100%',
    justifyContent: 'center',
    marginTop: 5,
  },
  buttonExample: {
    backgroundColor: '#1A251F',
    color: '#1E7343',
    borderColor: '#1b3c29',
    borderWidth: 1,
    fontSize: 12,
  }
}

export default Sidebar
