/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react"
import {
  FormGroup, Label, Input, Button
} from 'reactstrap';
import copy from 'copy-to-clipboard'
import ImageUploader from 'react-images-upload';
import { useSelector } from "react-redux";
import { Navbar, List, Grid, Tooltips } from "../../components";
import { darkTheme, lightTheme } from './styles'
import { elementor } from "../../assets/elementor";
import * as Icon from 'react-feather';
import { colors } from "../../utils";
import './styles.css'
import { ICHeadline, ICSubheadline, ICDeskripsi, ICWhatsapp, ICBenefit } from "../../assets/icon";
import { useForm } from "react-hook-form";

const Home = () => {
  const HomeReducer = useSelector(state => state.HomeReducer)
  const OnBoardingReducer = useSelector(state => state.OnBoardingReducer)

  const [targetPengguna, setTargetPengguna] = useState(OnBoardingReducer.form.targetPengguna || '{Target_Pengguna}')
  const [solusi, setSolusi] = useState(OnBoardingReducer.form.solusi || '{Solusi}')
  const [masalah, setMasalah] = useState(OnBoardingReducer.form.masalah || '{Masalah}')
  const [jenisProduk, setJenisProduk] = useState(OnBoardingReducer.form.jenisProduk || '{Jenis_Produk}')
  const [hasil, setHasil] = useState(OnBoardingReducer.form.hasil || '{Hasil}')
  const [periode, setPeriode] = useState('{Periode}')
  const [tokohAhli, setTokohAhli] = useState(OnBoardingReducer.form.tokohAhli || '{Ahli}')
  const [number, setNumber] = useState('{Number}')
  const [produk, setProduk] = useState('{Nama_Produk}')
  const [alamat, setAlamat] = useState('{Alamat}')
  const [phone, setPhone] = useState('{No_Whatsapp}')
  const [web, setWeb] = useState('{Web}')
  const [selected, setSelected] = useState([])
  const [selectedSub, setSelectedSub] = useState([])
  const [selectedBenefit, setSelectedBenefit] = useState([])
  const [selectedDes, setSelectedDes] = useState([])
  const [value, setValue] = useState('Headline')
  const [list, setList] = useState(false)
  const [grid, setGrid] = useState(false)
  const [theme, setTheme] = useState(sessionStorage.getItem('theme') || 'dark')
  const [toggle, setToggle] = useState(true)
  const [iconToggle, setIconToggle] = useState(<Icon.ChevronsLeft size={20} color={colors.icon.secondary} />)
  const [navbarToggle, setNavbarToggle] = useState(false)
  const [save, setSave] = useState(false)
  const [button, setButton] = useState(false)
  const [switcher, setSwitcher] = useState(0)

  const contents = [
    {
      id: 1,
      status: 'Free',
      title: `<p>Pernah Tertipu Belanja Online? Belanja di <b><i>${produk}</i></b> Saja. Jaminan ORI dan Terpercaya !!</p>`,
    },
    {
      id: 2,
      status: 'Free',
      title: `<p><b><i>${solusi}</i></b> platform belanja dibuat untuk anda<p>`,
    },
    {
      id: 3,
      status: 'Free',
      title: `<p>Nggak Pernah Cocok belanja di online shop lain? Saatnya Kamu Belanja ke <b><i>${solusi}</i></b> ini<p>`,
    },
    {
      id: 4,
      status: 'Free',
      title: `<p>Mau belanja hemat? Itulah Kenapa Kamu Harus belanja di <b><i>${solusi}</i></b>. Produk Investasi Terbaik, Diawasi oleh OJK<p>`,
    },
    {
      id: 5,
      status: 'Free',
      title: `<p><b><i>${produk}</i></b> Online Shop no.1</b><p>`,
    },
    {
      id: 6,
      status: 'Free',
      title: `<p>8 dari 10 <b><i>${tokohAhli}</i></b> puas ketika belanja di <b><i>${produk}</i></b><p>`,
    },
    {
      id: 7,
      status: 'Free',
      title: `<p>Selamat datang di <b><i>${produk}</i></b><p>`,
    },
    {
      id: 8,
      status: 'Free',
      title: `<p><b><i>${produk}</i></b> Tempat belanja terbaik dan termudah se Indonesia<p>`,
    },
    {
      id: 9,
      status: 'Free',
      title: `<p>Belanja hemat hanya di <b><i>${produk}</i></b><p>`,
    },
    {
      id: 10,
      status: 'Free',
      title: `<p>Selamat datang di <b><i>${produk}</i></b><p>`,
    },
    {
      id: 11,
      status: 'Free',
      title: `<p><b><i>${produk}</i></b> Tempat belanja terbaik dan termudah se Indonesia<p>`,
    },
    {
      id: 12,
      status: 'Free',
      title: `<p>Belanja hemat hanya di <b><i>${produk}</i></b><p>`,
    },
    {
      id: 13,
      status: 'Free',
      title: `<p>Selamat datang di <b><i>${produk}</i></b><p>`,
    },
    {
      id: 14,
      status: 'Free',
      title: `<p><b><i>${produk}</i></b> Tempat belanja terbaik dan termudah se Indonesia<p>`,
    },
    {
      id: 15,
      status: 'Free',
      title: `<p>Belanja hemat hanya di <b><i>${produk}</i></b><p>`,
    },
  ]

  const subHeadlines = [
    {
      id: 1,
      status: 'Free',
      title: `<p>baik Anda berjualan secara online, di media sosial atau di luar bagasi mobil Anda, <b><i>${produk}</i></b> siap membantu Anda</p>`,
    },
    {
      id: 2,
      status: 'Free',
      title: `<p>Tidak perlu repot - repot <b><i>${masalah}</i></b> hanya butuh duduk di rumah dengan <b><i>${solusi}</i></b><p>`,
    },
    {
      id: 3,
      status: 'free',
      title: `<p>Cukup dengan sekali klik saja Anda bisa mendapatkan barang yang unik dan apik. Kini Anda tak perlu capek - capek keluar rumah untuk membeli, dengan <b><i>${produk}</i></b> semua beres.</p>`
    },
    {
      id: 4,
      status: 'Free',
      title: `<p>Ingin <b><i>${masalah}</i<</b>: dengan praktis? gunakan <b><i>${produk}</i></b><p>`,
    },
    {
      id: 5,
      status: 'Free',
      title: `<p><b><i>${produk}</i></b> online shop no.1</b><p>`,
    },
    {
      id: 6,
      status: 'Free',
      title: `<p>8 dari 10 <b><i>${tokohAhli}</i></b> puas ketika menggunakan <b><i>${produk}</i></b><p>`,
    },
    {
      id: 7,
      status: 'Free',
      title: `<p>Belanja produk terbaik hanya di <b>${produk}</b><p>`,
    },
    {
      id: 8,
      status: 'Free',
      title: `<p>Tempat terbaik <b>${targetPengguna}</b> untuk belanja <b>${hasil}</b><p>`,
    },
    {
      id: 9,
      status: 'Free',
      title: `<p>Simpan uang anda dengan belanja di <b>${produk}</b><p>`,
    },
    {
      id: 10,
      status: 'Free',
      title: `<p>Capek dengan <b><i>${masalah}</i></b>? coba belanja<b><i>${produk}</i></b> sekarang juga!</p>`,
    },
    {
      id: 11,
      status: 'Free',
      title: `<p>Ingin <b><i>${masalah}</i></b> beres tanpa repot?<b><i>${solusi}</i></b> solusinya<p>`,
    },
    {
      id: 12,
      status: 'Free',
      title: `<p><b><i>${tokohAhli}</i></b> saatnya beralih ke <b><i>${solusi}</i></b>, mengatasi <b><i>${masalah}</i></b> dengan cepat!<p>`,
    },
    {
      id: 13,
      status: 'Free',
      title: `<p>Ingin <b><i>${masalah}</i></b> beres tanpa repot?<b><i>${solusi}</i></b> solusinya<p>`,
    },
    {
      id: 14,
      status: 'Free',
      title: `<p><b><i>${tokohAhli}</i></b> saatnya beralih ke <b><i>${solusi}</i></b>, mengatasi <b><i>${masalah}</i></b> dengan cepat!<p>`,
    },
    {
      id: 15,
      status: 'Free',
      title: `<p><b><i>${tokohAhli}</i></b> saatnya beralih ke <b><i>${solusi}</i></b>, mengatasi <b><i>${masalah}</i></b> dengan cepat!<p>`,
    },
  ]

  const benefits = [
    {
      id: 1,
      status: 'free',
      title: `<p>Cukup dengan sekali klik saja Anda bisa mendapatkan teks landing page yang unik dan apik. Kini Anda tak perlu menunggu berminggu-minggu lamanya untuk memperoleh teks terbaik dengan <b><i>${produk}</i></b>.</p>`
    },
    {
      id: 2,
      status: 'free',
      title: `<p>Untuk apa membayar jasa freelancer jika <b><i>${produk}</i></b> bisa melakukannya secara gratis? Gunakan uang Anda untuk mengembangkan bisnis. Urusan konten, serahkan pada tool content generator.</p>`
    },
    {
      id: 3,
      status: 'free',
      title: `<p>Tidak akan ada lagi waktu yang terbuang. Dengan <b><i>${produk}</i></b> nikmati bisnis yang berkembang dari tahun ke tahun.</p>`
    },
    {
      id: 4,
      status: 'free',
      title: `<p>Selain unik, landing page yang dibuat oleh <b><i>${produk}</i></b> juga telah dioptimasi untuk SEO. Naikkan peringkat website Anda di mesin pencari dan bikin pengunjung jadi makin betah.</p>`
    },
    {
      id: 5,
      status: 'free',
      title: '<p>Harga dan kualitas pasti satu acuan yang sangat penting untuk para konsumen membeli suatu barang. Ingin sepatu kulit asli agar lebih awet dipakai, tapi apa daya kondisi keuangan Moms saat itu belum bisa membelinya. Tentu Moms akan memutuskan membeli sepatu kulit sintetis yang pastinya dengan harga yang lebih murah </p>'
    },
    {
      id: 6,
      status: 'free',
      title: '<p>Harga dan kualitas pasti satu acuan yang sangat penting untuk para konsumen membeli suatu barang. Ingin sepatu kulit asli agar lebih awet dipakai, tapi apa daya kondisi keuangan Moms saat itu belum bisa membelinya. Tentu Moms akan memutuskan membeli sepatu kulit sintetis yang pastinya dengan harga yang lebih murah </p>'
    },
    {
      id: 7,
      status: 'free',
      title: '<p>Harga dan kualitas pasti satu acuan yang sangat penting untuk para konsumen membeli suatu barang. Ingin sepatu kulit asli agar lebih awet dipakai, tapi apa daya kondisi keuangan Moms saat itu belum bisa membelinya. Tentu Moms akan memutuskan membeli sepatu kulit sintetis yang pastinya dengan harga yang lebih murah </p>'
    },
    {
      id: 8,
      status: 'free',
      title: '<p>Harga dan kualitas pasti satu acuan yang sangat penting untuk para konsumen membeli suatu barang. Ingin sepatu kulit asli agar lebih awet dipakai, tapi apa daya kondisi keuangan Moms saat itu belum bisa membelinya. Tentu Moms akan memutuskan membeli sepatu kulit sintetis yang pastinya dengan harga yang lebih murah </p>'
    },
  ]

  const deskripsis = [
    {
      id: 1,
      status: 'Free',
      title: `<p>Lebih dari sekadar Onlineshop, <b><i>${produk}</b></i> menyediakan teknologi sebagai solusi untuk memberdayakan ribuan penjual dan konsumen agar dapat berpartisipasi membangun masa depan perdagangan UMKM.</p>`,
    },
    {
      id: 2,
      status: 'Free',
      title: `<b><i>${produk}</i></b> adalah Onlineshop yang memiliki misi menciptakan perekonomian yang adil untuk semua. Melalui platform online dan offlinenya, <b><i>${produk}</i></b> memberikan kesempatan dan pilihan kepada semua orang untuk meraih hidup yang lebih baik.`
    },
    {
      id: 3,
      status: 'Free',
      title: `Kami percaya pada kekuatan transformatif teknologi dan ingin mengubah dunia perdagangan menjadi lebih baik dengan menyediakan platform untuk menghubungkan pembeli dan penjual dalam satu komunitas.`
    },
    {
      id: 4,
      status: 'Free',
      title: `$<b><i>${produk}</i></b> Toko online <b><i>${solusi}</i></b> & terpercaya dengan beragam produk pilihan serta promo menarik`
    },
    {
      id: 5,
      status: 'Free',
      title: `<p>Lebih dari sekadar Onlineshop, <b><i>${produk}</b></i> menyediakan teknologi sebagai solusi untuk memberdayakan ribuan penjual dan konsumen agar dapat berpartisipasi membangun masa depan perdagangan UMKM.</p>`,
    },
    {
      id: 6,
      status: 'Free',
      title: `<b><i>${produk}</i></b> adalah Onlineshop yang memiliki misi menciptakan perekonomian yang adil untuk semua. Melalui platform online dan offlinenya, <b><i>${produk}</i></b> memberikan kesempatan dan pilihan kepada semua orang untuk meraih hidup yang lebih baik.`
    },
  ]

  const sideBars = [
    {
      id: 1,
      label: 'Target Pengguna',
      name: 'targetPengguna',
      placeholder: 'Designer, Indiehackers',
      tooltip: {
        title: 'Untuk siapa produk anda ditargetkan?',
        example: 'Indiehackers, Doctors, Athlets, Fathers'
      },
      onChange: setTargetPengguna,
      value: targetPengguna,
    },
    {
      id: 2,
      label: 'Solution',
      name: 'solution',
      placeholder: 'Healthy Food, Faster Site',
      tooltip: {
        title: 'Apa solusi yang anda tawarkan?',
        example: 'Wix, Elementor'
      },
      onChange: setSolusi,
      value: solusi,
    },
    {
      id: 3,
      label: 'Masalah',
      name: 'problem',
      placeholder: 'Low Conversion Rate, Boring Copy',
      tooltip: {
        title: 'Masalah apa yang saat ini dimiliki audiens Anda yang sedang Anda pecahkan?',
        example: 'Malas ngetik, Sedikit budget'
      },
      onChange: setMasalah,
      value: masalah,
    },
    {
      id: 4,
      label: 'Jenis Produk',
      name: 'product',
      placeholder: 'Headlines That Convert, Design Tool',
      tooltip: {
        title: 'Apa produk anda?',
        example: 'Headlines That Convert, Design Tool'
      },
      onChange: setJenisProduk,
      value: jenisProduk,
    },
    {
      id: 5,
      label: 'Hasil Yang Dinginkan',
      name: 'desire',
      placeholder: 'Convert Visitors Into Leads',
      tooltip: {
        title: 'Apa yang ingin dicapai pelanggan Anda?',
        example: 'Convert Visitors Into Leads'
      },
      onChange: setHasil,
      value: hasil,
    },
    {
      id: 6,
      label: 'Periode',
      name: 'period',
      placeholder: 'X Months, Last Week',
      tooltip: {
        title: 'Digunakan untuk periode waktu berapa lama?',
        example: 'X Bulan, Akhir minggu'
      },
      onChange: setPeriode,
      value: periode
    },
    {
      id: 7,
      label: 'Tokoh Ahli',
      name: 'tokohAhli',
      placeholder: 'Copywriting Expert',
      tooltip: {
        title: 'Siapa seseorang atau sesuatu yang dipercaya atau dipandang oleh audiens Anda?',
        example: 'Influencers, Scientists'
      },
      onChange: setTokohAhli,
      value: tokohAhli
    },
    {
      id: 8,
      label: 'Number',
      name: 'number',
      placeholder: '86',
      tooltip: {
        title: 'Bisa berupa angka atau persentase',
        example: '83%, 593, 15.923'
      },
      onChange: setNumber,
      value: number
    },
    {
      id: 9,
      label: 'Produk',
      tooltip: {
        name: 'product',
        placeholder: 'Headlime, Landingfolio',
        title: 'Your product name',
        example: 'Headlime, Landingfolio'
      },
      onChange: setProduk,
      value: produk
    },
    {
      id: 19,
      label: 'Alamat',
      tooltip: {
        name: 'Alamat',
        placeholder: 'Headlime, Landingfolio',
        title: 'Alamat anda',
        example: 'Headlime, Landingfolio'
      },
      onChange: setAlamat,
      value: alamat
    },
    {
      id: 11,
      label: 'Whatsapp Follow-up',
      tooltip: {
        name: 'Whatsapp Follow-up',
        placeholder: 'Headlime, Landingfolio',
        title: 'Nomer whatsapp anda',
        example: 'Headlime, Landingfolio'
      },
      onChange: setPhone,
      value: phone,
    },
    {
      id: 12,
      label: 'Web',
      tooltip: {
        name: 'Web',
        placeholder: 'Website',
        title: 'Alamat website anda',
        example: 'Headlime, Landingfolio'
      },
      onChange: setWeb,
      value: web,
    },
  ]

  const formOptions = {
    tops: [
      {
        id: 1,
        value: 'Headline',
        title: 'Headline',
      },
      {
        id: 2,
        value: 'Sub headline',
        title: 'Sub headline',
      },
      {
        id: 3,
        value: 'Benefit',
        title: 'Benefit',
      },
      {
        id: 4,
        value: 'Deskripsi Produk',
        title: 'Deskripsi Produk',
      },
    ],
    categorys: [
      {
        id: 1,
        value: 'Landing Page',
        title: 'Landing Page',
      },
    ]
  }

  useEffect(() => {
    console.log('data elementor')
    console.log(elementor.content[1].elements[0].elements[1].settings.description);
    console.log(elementor.content[6].elements[1].elements[0].settings.icon_list[0].text)
    console.log(elementor.content[2].elements[0].elements[2].elements[1].elements[1].settings.icon_list[0].text)
    console.log(elementor.content[2].elements[0].elements[2].elements[1].elements[1].settings.icon_list[1].text)
    console.log(elementor.content[2].elements[0].elements[2].elements[1].elements[1].settings.icon_list[2].text)
    if (save) {
      setSelected(contents)
      setSelectedSub(subHeadlines)
      setSelectedBenefit(benefits)
      setSelectedDes(deskripsis)
    } else {
      setSelected([])
      setSelectedSub([])
      setSelectedBenefit([])
      setSelectedDes([])
    }
  }, [save])

  const onHandleCopy = (index) => {
    let arr = document.createElement('div')
    arr.innerHTML = index
    console.log(arr.innerText)
    copy(arr.innerText)
  }

  const onHandleSelect = (content) => {
    console.log(content, 'content')

    if (typeof selected.find(item => item.id === content.id) === 'undefined') {
      let data = selected.concat(content)
      console.log(data)
      setSelected(data)
    } else {
      let data = selected.filter(item => item.id !== content.id)
      setSelected(data)
    }
  }

  const onHandleSelectSubHeadline = (subHeadline) => {
    console.log(subHeadline, 'content')
    if (typeof selectedSub.find(item => item.id === subHeadline.id) === 'undefined') {
      let data = selectedSub.concat(subHeadline)
      console.log(data)
      setSelectedSub(data)
    } else {
      let data = selectedSub.filter(item => item.id !== subHeadline.id)
      setSelectedSub(data)
    }
  }

  const onHandleSelectBenefit = (benefit) => {
    console.log(benefit, 'content')
    if (typeof selectedBenefit.find(item => item.id === benefit.id) === 'undefined') {
      let data = selectedBenefit.concat(benefit)
      console.log(data)
      setSelectedBenefit(data)
    } else {
      let data = selectedBenefit.filter(item => item.id !== benefit.id)
      setSelectedBenefit(data)
    }
  }

  const onHandleSelectDeskripsi = (deskripsi) => {
    console.log(deskripsi, 'content')
    if (typeof selectedDes.find(item => item.id === deskripsi.id) === 'undefined') {
      let data = selectedDes.concat(deskripsi)
      console.log(data)
      setSelectedDes(data)
    } else {
      let data = selectedDes.filter(item => item.id !== deskripsi.id)
      setSelectedDes(data)
    }
  }

  const onHandleExport = () => {
    let elementorData = elementor
    let arr = document.createElement('div')
    let dataProduk = produk
    let data = selected.map(item => item.title)
    let dataSubheadline = selectedSub.map(item => item.title)
    let dataBenefit = selectedBenefit[0].title
    let dataBenefit2 = selectedBenefit[1].title
    let dataBenefit3 = selectedBenefit[2].title
    // console.log('benefit 1', dataBenefit)
    // console.log('benefit 2', dataBenefit2)
    // console.log('benefit 3', dataBenefit3)
    let dataDeskripsi = selectedDes.map(item => item.title)
    let dataWeb = web
    let dataAlamat = alamat
    let dataPhone = phone
    let newDataProduk = dataProduk.toString()
    let newDataHeadline = data.toString()
    let newDataSubheadline = dataSubheadline.toString()
    let newDataBenefit = dataBenefit.toString()
    let newDataBenefit2 = dataBenefit2.toString()
    let newDataBenefit3 = dataBenefit3.toString()
    console.log('benefit 1', newDataBenefit)
    console.log('benefit 2', newDataBenefit2)
    console.log('benefit 3', newDataBenefit3)
    let newDataDeskripsi = dataDeskripsi.toString()
    let newDataAlamat = dataAlamat.toString()
    let newDataPhone = dataPhone.toString()
    let newDataWeb = dataWeb.toString()
    if (selected === null) {
      console.log('true')
    } else {
      if (selected.length > 0) {
        let arr = document.createElement('div')
        arr.innerHTML = JSON.stringify(newDataProduk)
        arr.innerHTML = JSON.stringify(newDataHeadline)
        arr.innerHTML = JSON.stringify(newDataSubheadline)
        arr.innerHTML = JSON.stringify(newDataBenefit)
        arr.innerHTML = JSON.stringify(newDataBenefit2)
        arr.innerHTML = JSON.stringify(newDataBenefit3)
        arr.innerHTML = JSON.stringify(newDataDeskripsi)
        arr.innerHTML = JSON.stringify(newDataAlamat)
        arr.innerHTML = JSON.stringify(newDataWeb)
        elementorData.content[0].elements[1].elements[0].settings.title = newDataProduk
        elementorData.content[1].elements[0].elements[1].settings.title = newDataHeadline
        elementorData.content[1].elements[0].elements[1].settings.description = newDataSubheadline
        elementorData.content[2].elements[0].elements[2].elements[1].elements[1].settings.icon_list[0].text = newDataBenefit
        elementorData.content[2].elements[0].elements[2].elements[1].elements[1].settings.icon_list[1].text = newDataBenefit2
        elementorData.content[2].elements[0].elements[2].elements[1].elements[1].settings.icon_list[2].text = newDataBenefit3
        elementorData.content[2].elements[0].elements[2].elements[0].elements[1].settings.editor = newDataDeskripsi
        elementorData.content[5].elements[0].elements[3].elements[0].elements[0].settings.address = newDataAlamat
        elementorData.content[5].elements[0].elements[3].elements[1].elements[0].settings.icon_list[0].text = newDataAlamat
        elementorData.content[5].elements[0].elements[3].elements[1].elements[0].settings.icon_list[1].text = newDataPhone
        elementorData.content[5].elements[0].elements[3].elements[1].elements[0].settings.icon_list[2].text = newDataWeb
        elementorData.content[6].elements[1].elements[0].settings.icon_list[0].text = `@2018 - 2020 ${newDataProduk}`
        elementorData.content[2].elements[0].elements[2].settings.editor = newDataDeskripsi
        let dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(elementorData));
        let exportFileDefaultName = 'copycake.json';
        let linkElement = document.createElement('a');
        linkElement.setAttribute('href', dataUri);
        linkElement.setAttribute('download', exportFileDefaultName);
        linkElement.click();
      }
      return null
    }
  }

  const onHandleSave = () => {
    setSave(!save)
  }

  const onHandleSample = () => {
    const { targetPengguna, solusi, masalah, jenisProduk, hasil, periode, tokohAhli, number, produk } = HomeReducer.samples
    setTargetPengguna(targetPengguna[Math.floor(Math.random() * targetPengguna.length)])
    setSolusi(solusi[Math.floor(Math.random() * solusi.length)])
    setMasalah(masalah[Math.floor(Math.random() * masalah.length)])
    setJenisProduk(jenisProduk[Math.floor(Math.random() * jenisProduk.length)])
    setHasil(hasil[Math.floor(Math.random() * hasil.length)])
    setTokohAhli(tokohAhli[Math.floor(Math.random() * tokohAhli.length)])
    setNumber(number[Math.floor(Math.random() * number.length)])
    setPeriode(periode[Math.floor(Math.random() * periode.length)])
    setProduk(produk[Math.floor(Math.random() * produk.length)])
  }

  const onHandleList = () => {
    setList(true)
    setGrid(false)
    setButton(false)
  }

  const onHandleGrid = () => {
    setGrid(true)
    setList(false)
    setButton(true)
  }

  const onHandleTheme = () => {
    if (theme === 'dark') {
      window.sessionStorage.setItem('theme', 'light')
      setTheme('light')
      console.log('light')
    } else {
      window.sessionStorage.setItem('theme', 'dark')
      setTheme('dark')
      console.log('dark')
    }
    // setTheme(!theme)
    // sessionStorage.clear()
  }

  useEffect(() => {
    const localTheme = window.sessionStorage.getItem('theme');
    localTheme && setTheme(localTheme);
  }, []);

  const onHandleToggle = () => {
    setToggle(!toggle)
    setIconToggle(toggle ? <Icon.ChevronsRight size={20} color={colors.icon.secondary} style={styles.icon} /> : <Icon.ChevronsLeft size={20} color={colors.icon.secondary} style={styles.icon} />)
    setNavbarToggle(!navbarToggle)
  }

  const styles = theme === 'dark' ? darkTheme : lightTheme
  const iconDropDown = theme === 'dark' ? <Icon.Moon size={14} className="mr-2" /> : <Icon.Sun size={14} className="mr-2" />
  const titleDropDown = theme === 'dark' ? "Dark Mode" : "Light Mode"

  const { tops, categorys } = formOptions
  return (
    <div className="" style={{ backgroundColor: theme === 'light' ? colors.background.tertiary : colors.background.primary }}>
      <Navbar
        theme={theme === 'light' ? 'light' : ''}
        onHandleExport={() => onHandleExport(contents.title)}
        onHandleSave={() => onHandleSave(contents.title)}
        onHandleTheme={onHandleTheme}
        onHandleToggle={onHandleToggle}
        icon={iconToggle}
        iconDropDown={iconDropDown}
        titleDropDown={titleDropDown}
        valueSwitch={switcher}
      />
      {/* <nav class="navbar navbar-expand-sm bg-dark navbar-dark">
        <a class="navbar-brand" href="#">Navbar</a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="collapsibleNavbar">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link" href="#">Link</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Link</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Link</a>
            </li>
          </ul>
        </div>
      </nav> */}
      <div style={{ marginTop: '10px' }}>
        <div className="row">
          <div
            className="sidebar sidebars col-sm-3"
            style={navbarToggle ? styles.containerSecond : styles.container}>
            <div class="p-2 pt-5" style={styles.sidebar}>
              <FormGroup className="formgroup">
                <select className="p-2 mt-4" onChange={(e) => setValue(e.target.value)} value={value} style={navbarToggle ? styles.formSelectSecondary : styles.formSelect}>
                  {tops.map(((top, index) => {
                    return (
                      <option key={index} value={top.value}>{top.title}</option>
                    )
                  }))}
                </select>
              </FormGroup>
              <FormGroup className="formgroup">
                <select className="p-2" style={navbarToggle ? styles.formSelectSecondary : styles.formSelect}>
                  {categorys.map(((category, index) => {
                    return (
                      <option key={index} value={category.value}>{category.title}</option>
                    )
                  }))}
                </select>
              </FormGroup>
              <div className="example d-flex justify-content-between pb-1 pt-2">
                <div className="align-items-center">
                  <Icon.Code size={16} color={colors.icon.primary} style={styles.iconCode} />
                  <Label for="exampleSelect" style={styles.label}>VARIABEL</Label>
                </div>
                <button className="btn-gunakan btn btn-sm" style={styles.buttonExample} onClick={onHandleSample}>Gunakan Contoh</button>
              </div>
              <div className="sidebar mb-4 flex flex-col">
                {sideBars.map((item, index) => {
                  return (
                    <FormGroup key={index}>
                      <div className="icon icon_cow align-items-center d-flex justify-content-between">
                        <Label for="exampleEmail" style={styles.label}>{item.label}</Label>
                        {theme === 'light' ? <Tooltips theme="light" title={item.tooltip.title} example={item.tooltip.example} /> : <Tooltips title={item.tooltip.title} example={item.tooltip.example} />}
                      </div>
                      <Input type="text" name={item.name} id={item.id} placeholder={item.placeholder} value={item.value}
                        onChange={(e) => item.onChange(e.target.value)} style={navbarToggle ? styles.inputSecond : styles.input} />
                    </FormGroup>
                  )
                })}
              </div>
            </div>
          </div>
          {
            value === 'Headline' ?
              <div id="select" className="content col-sm-9 ml-auto mt-5" style={styles.content}>
                <div className="p-2">
                  <div className="d-flex flex-row align-items-center">
                    <div className="mr-2">
                      <img src={ICHeadline} style={{ width: '20px', height: '20px' }} />
                    </div>
                    <h2 className="pb-2" style={styles.title}>Headline</h2>
                    <div className="d-flex align-items-center ml-auto">
                      <p style={styles.inputUrutkan}>Urutkan :</p>
                      <FormGroup>
                        <Input type="select" name="select" id="exampleSelect" style={styles.inputUrutkan}>
                          <option>Populer</option>
                          <option>Terbaru</option>
                        </Input>
                      </FormGroup>
                      <div className="pl-2 mb-3">
                        <Button className="py-1" href="" onClick={onHandleList} style={{ borderRadius: 0, borderTopLeftRadius: 2, borderBottomLeftRadius: 2, backgroundColor: theme ? button ? 'transparent' : colors.background.quinary : button ? colors.background.secondary : colors.background.quinary, borderColor: colors.border.secondary }}>
                          <Icon.Grid size={13} color={colors.icon.secondary} style={{ alignSelf: 'center' }} />
                        </Button>
                        <Button className="py-1" href="" onClick={onHandleGrid} style={{ borderRadius: 0, borderTopRightRadius: 2, borderBottomRightRadius: 2, backgroundColor: theme ? button ? colors.background.quinary : 'transparent' : button ? colors.background.quinary : colors.background.secondary, borderColor: colors.border.secondary }}>
                          <Icon.List size={13} color={colors.icon.secondary} />
                        </Button>
                      </div>
                    </div>
                  </div>
                  {
                    grid ? <div className="">
                      {contents.map((content, index) => {
                        return (
                          <Grid
                            key={index}
                            sm={4}
                            theme={theme === 'light' ? 'light' : ''}
                            color={theme === 'light' ? typeof selected.find(item => item.id === content.id) !== 'undefined' ? 'dark' : '' : typeof selected.find(item => item.id === content.id) !== 'undefined' ? 'light' : ''}
                            dangerouslySetInnerHTML={{ __html: content.title }}
                            onClickCopy={() => onHandleCopy(content.title)}
                            onClickSelect={() => onHandleSelect(content)}
                          />
                        )
                      })}
                    </div> : <div className="d-flex row">
                        {contents.map((content, index) => {
                          return (
                            <List
                              key={index}
                              sm={4}
                              theme={theme === 'light' ? 'light' : ''}
                              color={theme === 'light' ? typeof selected.find(item => item.id === content.id) !== 'undefined' ? 'dark' : '' : typeof selected.find(item => item.id === content.id) !== 'undefined' ? 'light' : ''}
                              dangerouslySetInnerHTML={{ __html: content.title }}
                              onClickCopy={() => onHandleCopy(content.title)}
                              onClickSelect={() => onHandleSelect(content)}
                            />
                          )
                        })}
                      </div>
                  }
                </div>
              </div>
              : value === 'Sub headline' ?
              <div id="select" className="content col-sm-9 ml-auto mt-5" style={styles.content}>
              <div className="p-2">
                <div className="d-flex flex-row align-items-center">
                  <div className="mr-2">
                    <img src={ICSubheadline} style={{ width: '20px', height: '20px' }} />
                  </div>
                  <h2 className="pb-2" style={styles.title}>Subheadline</h2>
                  <div className="d-flex align-items-center ml-auto">
                    <p style={styles.inputUrutkan}>Urutkan :</p>
                    <FormGroup>
                      <Input type="select" name="select" id="exampleSelect" style={styles.inputUrutkan}>
                        <option>Populer</option>
                        <option>Terbaru</option>
                      </Input>
                    </FormGroup>
                    <div className="pl-2 mb-3">
                      <Button className="py-1" href="" onClick={onHandleList} style={{ borderRadius: 0, borderTopLeftRadius: 2, borderBottomLeftRadius: 2, backgroundColor: theme ? button ? 'transparent' : colors.background.quinary : button ? colors.background.secondary : colors.background.quinary, borderColor: colors.border.secondary }}>
                        <Icon.Grid size={13} color={colors.icon.secondary} style={{ alignSelf: 'center' }} />
                      </Button>
                      <Button className="py-1" href="" onClick={onHandleGrid} style={{ borderRadius: 0, borderTopRightRadius: 2, borderBottomRightRadius: 2, backgroundColor: theme ? button ? colors.background.quinary : 'transparent' : button ? colors.background.quinary : colors.background.secondary, borderColor: colors.border.secondary }}>
                        <Icon.List size={13} color={colors.icon.secondary} />
                      </Button>
                    </div>
                  </div>
                </div>
                {
                  grid ? <div className="">
                    {subHeadlines.map((subHeadline, index) => {
                      return (
                        <Grid
                          key={index}
                          sm={4}
                          theme={theme === 'light' ? 'light' : ''}
                          color={theme === 'light' ? typeof selectedSub.find(item => item.id === subHeadline.id) !== 'undefined' ? 'dark' : '' : typeof selectedSub.find(item => item.id === subHeadline.id) !== 'undefined' ? 'light' : ''}
                          dangerouslySetInnerHTML={{ __html: subHeadline.title }}
                          onClickCopy={() => onHandleCopy(subHeadline.title)}
                          onClickSelect={() => onHandleSelectSubHeadline(subHeadline)}
                        />
                      )
                    })}
                  </div> : <div className="d-flex row">
                      {subHeadlines.map((subHeadline, index) => {
                        return (
                          <List
                            key={index}
                            sm={4}
                            theme={theme === 'light' ? 'light' : ''}
                            color={theme === 'light' ? typeof selectedSub.find(item => item.id === subHeadline.id) !== 'undefined' ? 'dark' : '' : typeof selectedSub.find(item => item.id === subHeadline.id) !== 'undefined' ? 'light' : ''}
                            dangerouslySetInnerHTML={{ __html: subHeadline.title }}
                            onClickCopy={() => onHandleCopy(subHeadline.title)}
                            onClickSelect={() => onHandleSelectSubHeadline(subHeadline)}
                          />
                        )
                      })}
                    </div>
                }
              </div>
            </div>
                : value === 'Benefit' ?
                <div id="select" className="content col-sm-9 ml-auto mt-5" style={styles.content}>
                <div className="p-2">
                  <div className="d-flex flex-row align-items-center">
                    <div className="mr-2">
                      <img src={ICSubheadline} style={{ width: '20px', height: '20px' }} />
                    </div>
                    <h2 className="pb-2" style={styles.title}>Benefit</h2>
                    <div className="d-flex align-items-center ml-auto">
                      <p style={styles.inputUrutkan}>Urutkan :</p>
                      <FormGroup>
                        <Input type="select" name="select" id="exampleSelect" style={styles.inputUrutkan}>
                          <option>Populer</option>
                          <option>Terbaru</option>
                        </Input>
                      </FormGroup>
                      <div className="pl-2 mb-3">
                        <Button className="py-1" href="" onClick={onHandleList} style={{ borderRadius: 0, borderTopLeftRadius: 2, borderBottomLeftRadius: 2, backgroundColor: theme ? button ? 'transparent' : colors.background.quinary : button ? colors.background.secondary : colors.background.quinary, borderColor: colors.border.secondary }}>
                          <Icon.Grid size={13} color={colors.icon.secondary} style={{ alignSelf: 'center' }} />
                        </Button>
                        <Button className="py-1" href="" onClick={onHandleGrid} style={{ borderRadius: 0, borderTopRightRadius: 2, borderBottomRightRadius: 2, backgroundColor: theme ? button ? colors.background.quinary : 'transparent' : button ? colors.background.quinary : colors.background.secondary, borderColor: colors.border.secondary }}>
                          <Icon.List size={13} color={colors.icon.secondary} />
                        </Button>
                      </div>
                    </div>
                  </div>
                  {
                    grid ? <div className="">
                      {benefits.map((benefit, index) => {
                        return (
                          <Grid
                            key={index}
                            sm={5}
                            theme={theme === 'light' ? 'light' : ''}
                            color={theme === 'light' ? typeof selectedBenefit.find(item => item.id === benefit.id) !== 'undefined' ? 'dark' : '' : typeof selectedBenefit.find(item => item.id === benefit.id) !== 'undefined' ? 'light' : ''}
                            dangerouslySetInnerHTML={{ __html: benefit.title }}
                            onClickCopy={() => onHandleCopy(benefit.title)}
                            onClickSelect={() => onHandleSelectBenefit(benefit)}
                          />
                        )
                      })}
                    </div> : <div className="d-flex row">
                        {benefits.map((benefit, index) => {
                          return (
                            <List
                              key={index}
                              sm={6}
                              theme={theme === 'light' ? 'light' : ''}
                              color={theme === 'light' ? typeof selectedBenefit.find(item => item.id === benefit.id) !== 'undefined' ? 'dark' : '' : typeof selectedBenefit.find(item => item.id === benefit.id) !== 'undefined' ? 'light' : ''}
                              dangerouslySetInnerHTML={{ __html: benefit.title }}
                              onClickCopy={() => onHandleCopy(benefit.title)}
                              onClickSelect={() => onHandleSelectBenefit(benefit)}
                            />
                          )
                        })}
                      </div>
                  }
                </div>
              </div>
                  // <div id="select" className="content col-9 ml-auto mt-5" style={styles.content}>
                  //   <div className="p-2">
                  //     <div className="d-flex align-items-center">
                  //       <div className="mr-2">
                  //         <img src={ICBenefit} style={{ width: 20, height: 20 }} />
                  //       </div>
                  //       <h2 className="pb-2" style={styles.title}>Benefit</h2>
                  //       <div className="d-flex align-items-center ml-auto">
                  //         <p style={styles.inputUrutkan}>Urutkan :</p>
                  //         <FormGroup>
                  //           <Input type="select" name="select" id="exampleSelect" style={styles.inputUrutkan}>
                  //             <option>Populer</option>
                  //             <option>Terbaru</option>
                  //           </Input>
                  //         </FormGroup>
                  //         <div className="pl-2 mb-3">
                  //           <Button className="py-1" href="" onClick={onHandleList} style={{ borderRadius: 0, borderTopLeftRadius: 2, borderBottomLeftRadius: 2, backgroundColor: theme ? button ? 'transparent' : colors.background.quinary : button ? colors.background.secondary : colors.background.quinary, borderColor: colors.border.secondary }}>
                  //             <Icon.Grid size={13} color={colors.icon.secondary} style={{ alignSelf: 'center' }} />
                  //           </Button>
                  //           <Button className="py-1" href="" onClick={onHandleGrid} style={{ borderRadius: 0, borderTopRightRadius: 2, borderBottomRightRadius: 2, backgroundColor: theme ? button ? colors.background.quinary : 'transparent' : button ? colors.background.quinary : colors.background.secondary, borderColor: colors.border.secondary }}>
                  //             <Icon.List size={13} color={colors.icon.secondary} />
                  //           </Button>
                  //         </div>
                  //       </div>
                  //     </div>
                  //     {
                  //       grid ? <div className="">
                  //         {benefits.map((benefit, index) => {
                  //           return (
                  //             <Grid
                  //               key={index}
                  //               sm={4}
                  //               theme={theme === 'light' ? 'light' : ''}
                  //               type="secondary"
                  //               color={theme === 'light' ? typeof selectedBenefit.find(item => item.id === benefit.id) !== 'undefined' ? 'dark' : '' : typeof selectedBenefit.find(item => item.id === benefit.id) !== 'undefined' ? 'light' : ''}
                  //               dangerouslySetInnerHTML={{ __html: benefit.title }}
                  //               onClickCopy={() => onHandleCopy(benefit.title)}
                  //               onClickSelect={() => onHandleSelectBenefit(benefit)}
                  //             />
                  //           )
                  //         })}
                  //       </div> : <div className="d-flex row">
                  //           {benefits.map((benefit, index) => {
                  //             return (
                  //               <List
                  //                 key={index}
                  //                 sm={6}
                  //                 theme={theme === 'light' ? 'light' : ''}
                  //                 type="secondary"
                  //                 color={theme === 'light' ? typeof selectedBenefit.find(item => item.id === benefit.id) !== 'undefined' ? 'dark' : '' : typeof selectedBenefit.find(item => item.id === benefit.id) !== 'undefined' ? 'light' : ''}
                  //                 dangerouslySetInnerHTML={{ __html: benefit.title }}
                  //                 onClickCopy={() => onHandleCopy(benefit.title)}
                  //                 onClickSelect={() => onHandleSelectBenefit(benefit)}
                  //               />
                  //             )
                  //           })}
                  //         </div>
                  //     }
                  //   </div>
                  // </div>
                  : value === 'Deskripsi Produk' ?
                  <div id="select" className="content col-sm-9 ml-auto mt-5" style={styles.content}>
                  <div className="p-2">
                    <div className="d-flex flex-row align-items-center">
                      <div className="mr-2">
                        <img src={ICSubheadline} style={{ width: '20px', height: '20px' }} />
                      </div>
                      <h2 className="pb-2" style={styles.title}>Deskripsi</h2>
                      <div className="d-flex align-items-center ml-auto">
                        <p style={styles.inputUrutkan}>Urutkan :</p>
                        <FormGroup>
                          <Input type="select" name="select" id="exampleSelect" style={styles.inputUrutkan}>
                            <option>Populer</option>
                            <option>Terbaru</option>
                          </Input>
                        </FormGroup>
                        <div className="pl-2 mb-3">
                          <Button className="py-1" href="" onClick={onHandleList} style={{ borderRadius: 0, borderTopLeftRadius: 2, borderBottomLeftRadius: 2, backgroundColor: theme ? button ? 'transparent' : colors.background.quinary : button ? colors.background.secondary : colors.background.quinary, borderColor: colors.border.secondary }}>
                            <Icon.Grid size={13} color={colors.icon.secondary} style={{ alignSelf: 'center' }} />
                          </Button>
                          <Button className="py-1" href="" onClick={onHandleGrid} style={{ borderRadius: 0, borderTopRightRadius: 2, borderBottomRightRadius: 2, backgroundColor: theme ? button ? colors.background.quinary : 'transparent' : button ? colors.background.quinary : colors.background.secondary, borderColor: colors.border.secondary }}>
                            <Icon.List size={13} color={colors.icon.secondary} />
                          </Button>
                        </div>
                      </div>
                    </div>
                    {
                      grid ? <div className="">
                        {deskripsis.map((deskripsi, index) => {
                          return (
                            <Grid
                              key={index}
                              sm={5}
                              theme={theme === 'light' ? 'light' : ''}
                              color={theme === 'light' ? typeof selectedDes.find(item => item.id === deskripsi.id) !== 'undefined' ? 'dark' : '' : typeof selectedDes.find(item => item.id === deskripsi.id) !== 'undefined' ? 'light' : ''}
                              dangerouslySetInnerHTML={{ __html: deskripsi.title }}
                              onClickCopy={() => onHandleCopy(deskripsi.title)}
                              onClickSelect={() => onHandleSelectDeskripsi(deskripsi)}
                            />
                          )
                        })}
                      </div> : <div className="d-flex row">
                          {deskripsis.map((deskripsi, index) => {
                            return (
                              <List
                                key={index}
                                sm={6}
                                theme={theme === 'light' ? 'light' : ''}
                                color={theme === 'light' ? typeof selectedDes.find(item => item.id === deskripsi.id) !== 'undefined' ? 'dark' : '' : typeof selectedDes.find(item => item.id === deskripsi.id) !== 'undefined' ? 'light' : ''}
                                dangerouslySetInnerHTML={{ __html: deskripsi.title }}
                                onClickCopy={() => onHandleCopy(deskripsi.title)}
                                onClickSelect={() => onHandleSelectDeskripsi(deskripsi)}
                              />
                            )
                          })}
                        </div>
                    }
                  </div>
                </div> : null
          }
        </div>
      </div>
    </div>
  )
}

export default Home
