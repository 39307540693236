import React from 'react'

const Chart = () => {
    return (
        <div>
            <h4>Chart</h4>
        </div>
    )
}

export default Chart
