import React from 'react'
import {
  Card, CardTitle, CardText
} from 'reactstrap';
import * as Icon from 'react-feather';
import Tooltip from 'react-tooltip-lite';
import {styles} from './styles'
import { colors } from '../../../utils';

const Tooltips = ({theme, example, title}) => {
  if(theme === 'light') {
    return (
      <Tooltip
        content={(
          <Card body inverse style={{ backgroundColor: colors.background.tertiary, padding: 5, width: 180}}>
            <CardTitle style={{ fontSize: 9, color: colors.text.senary }}>
              {title}
            </CardTitle>
            <CardText style={{ fontSize: 9, color: colors.text.senary }}>Contoh: {example}</CardText>
          </Card>
        )}
        arrow={false}
        direction="left-start"
        tagName="span"
        className="target"
      >
        <Icon.HelpCircle className="tooltip-on-hover" size={13} color={colors.icon.primary} style={styles.icon} />
      </Tooltip>
    )
  }
  return (
    <Tooltip
      content={(
        <Card body inverse style={{ backgroundColor: colors.background.secondary, padding: 5, width: 180}}>
          <CardTitle style={{ fontSize: 9, color: colors.text.primary }}>
            {title}
          </CardTitle>
          <CardText style={{ fontSize: 9, color: colors.text.primary }}>Contoh: {example}</CardText>
        </Card>
      )}
      arrow={false}
      direction="left-start"
      tagName="span"
      className="target"
    >
      <Icon.HelpCircle className="tooltip-on-hover" size={13} color={colors.icon.primary} style={styles.icon} />
    </Tooltip>
  )
}

export default Tooltips
