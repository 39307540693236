import React from 'react'
import {
  Row, Card, Button,
} from 'reactstrap';
import { stylesDark, stylesLight } from './styles'
import './styles.css'

const Grid = ({ theme, color, dangerouslySetInnerHTML, onClickSelect, onClickCopy }) => {
  if (theme === 'light') {
    return (
      <Card className="mb-2" body outline color={color} style={stylesLight.cardList}>
        <div className="mb-2">
          <Row>
            <div className="pl-2" style={stylesLight.tag}>
              <p disabled className="btn btn-sm mr-1 transparent" style={stylesLight.titleTag}>Landing Page</p>
            </div>
            <div className="pr-2 ml-auto" style={stylesLight.wrapperButtonBottom}>
              <button className="btn btn-sm" style={stylesLight.button} onClick={onClickCopy}>Copy</button>
              <button className="btn btn-sm mx-1" style={stylesLight.button} onClick={onClickSelect}>Select</button>
            </div>
          </Row>
        </div>
        <div className="py-1" style={stylesLight.wrapperTitle}>
          <p dangerouslySetInnerHTML={dangerouslySetInnerHTML} style={stylesLight.titleCard}></p>
        </div>
      </Card>
    )
  }
  return (
    <Card className="mb-2" body outline color={color} style={stylesDark.cardList}>
      <div className="mb-1 p-1">
        <Row>
          <div className="pl-2" style={stylesDark.tag}>
            <p disabled color="secondary" className="btn btn-transparent btn-sm mr-1" style={stylesDark.titleTag}>Landing Page</p>
          </div>
          <div className="pr-2 ml-auto" style={stylesDark.wrapperButtonBottom}>
            <button className="btn btn-sm" style={stylesDark.button} onClick={onClickCopy}>Copy</button>
            <button className="btn btn-sm mx-1" style={stylesDark.button} onClick={onClickSelect}>Select</button>
          </div>
        </Row>
      </div>
      <div className="py-1" style={stylesDark.wrapperTitle}>
        <p dangerouslySetInnerHTML={dangerouslySetInnerHTML} style={stylesDark.titleCard}></p>
      </div>
    </Card>
  )
}

export default Grid
