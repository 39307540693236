/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import {
  Navbar, Button, NavItem, NavbarBrand, Collapse, Nav, DropdownMenu, Dropdown, DropdownToggle, NavbarToggler, Row
} from 'reactstrap';
import Switch from 'react-input-switch';
import { colors } from '../../../utils';
import * as Icon from 'react-feather';
import { ICCopyCake, ICCopyCakeBlack } from '../../../assets/icon';
import { stylesLight, stylesDark } from './styles'
import './styles.css'

const NavbarComponent = ({ theme, onHandleExport, onHandleLogin, onHandleSave, onHandleSignUp, onHandleTheme, onHandleToggle, icon, iconDropDown, titleDropDown, valueSwitch }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [value, setValue] = useState(sessionStorage.getItem('theme') === 'light' ? 1 : 0)
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => {
    setDropdownOpen(!dropdownOpen)
  }
  const toggler = () => {
    setIsOpen(!isOpen)
  }


  const onHandleOnOff = () => {
    setValue(value === 0 ? 1 : 0)
  }

  if (theme === 'light') {
    return (
      <div style={stylesLight.container} className="">
        <Navbar inverse expand="md" style={stylesLight.navbar} className="navbar">
          <NavbarBrand className="px-1" href="/" style={stylesLight.brand}>
            <img src={ICCopyCakeBlack} className="mr-1 mt-4"/>
            copycake
        </NavbarBrand>
          <NavbarBrand className="navicon">
            <Button className="btnIcon" color="transparent" outline style={stylesLight.btnIcon} onClick={onHandleToggle}>
              {icon}
            </Button>
          </NavbarBrand>
          <NavbarToggler onClick={toggler} />
          <Collapse navbar isOpen={isOpen} >
            <Nav className="ml-auto" navbar style={{ backgroundColor: colors.background.tertiary, padding: 0 }}>
              <NavItem className="navitems p-1">
                <Button color="btn transparent" style={stylesLight.title} onClick={onHandleExport}>Ekspor</Button>
              </NavItem>
              <NavItem className="navitems p-1">
                <Button color="transparent" style={stylesLight.title} onClick={onHandleSave}>Simpan</Button>
              </NavItem>
              <NavItem className="navitems p-1">
                <Button color="transparent" style={stylesLight.title} onClick={onHandleLogin}>Masuk</Button>
              </NavItem>
              <NavItem className="navitems-in p-1">
                <Button color="transparent" style={stylesLight.title} onClick={onHandleSignUp}>Daftar</Button>
              </NavItem>
              <NavItem className="p-1">
                <Dropdown isOpen={dropdownOpen} toggle={toggle} >
                  <DropdownToggle style={{ backgroundColor: colors.background.tertiary }}>
                    <Icon.MoreHorizontal size={20} color={colors.icon.tertiary} style={stylesLight.icon} />
                  </DropdownToggle>
                  <DropdownMenu right className="bg-white dropdown-menu justify-content-center align-items-center" >
                    <Row>
                    <Button color="transparent" className="ml-3" style={stylesLight.title}>
                      {iconDropDown}
                      <span className="align-middle">{titleDropDown}</span>
                    </Button>
                    <Switch
                      className="mt-3"
                      value={value}
                      onChange={() => { onHandleTheme(); onHandleOnOff() }} style={styles.switch}
                    />
                    </Row>
                    <Button color="transparent" style={stylesLight.title}>
                      <Icon.Sidebar size={14} className="mr-2" />
                      <span className="align-middle">Sidebar Mode</span>
                    </Button>
                  </DropdownMenu>
                </Dropdown>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    )
  }
  return (
    <div style={stylesDark.container}>
      <Navbar inverse light expand="md" className="navbar-style" style={stylesDark.navbar}>
        <NavbarBrand href="/" className="mr-5" style={stylesDark.brand}>
          <img src={ICCopyCake} className="mr-1 mt-4"/>
          copycake
        </NavbarBrand>
        <NavbarBrand className="navicon" style={{marginLeft: '110px'}}>
          <Button className="btnIcon" color="transparent" outline style={stylesDark.title} onClick={onHandleToggle}>
            {icon}
          </Button>
        </NavbarBrand>
        <NavbarToggler onClick={toggler} />
        <Collapse isOpen={isOpen} navbar style={{ backgroundColor: colors.background.secondary, padding: 0 }}>
          <Nav className="ml-auto" navbar style={{ backgroundColor: colors.background.secondary, padding: 0 }}>
            <NavItem className="navitems p-1">
              <Button color="transparent" style={stylesDark.title} onClick={onHandleExport}>Ekspor</Button>
            </NavItem>
            <NavItem className="navitems p-1">
              <Button color="transparent" style={stylesDark.title} onClick={onHandleSave}>Simpan</Button>
            </NavItem>
            <NavItem className="navitems p-1">
              <Button color="transparent" style={stylesDark.title} onClick={onHandleLogin}>Masuk</Button>
            </NavItem>
            <NavItem className="navitems-in p-1">
              <Button color="transparent" style={stylesDark.title} onClick={onHandleSignUp}>Daftar</Button>
            </NavItem>
            <NavItem className="p-1" >
              <Dropdown isOpen={dropdownOpen} toggle={toggle} >
                <DropdownToggle style={{ backgroundColor: colors.background.secondary, border: 0 }}>
                  <Icon.MoreHorizontal size={20} color={colors.icon.secondary} style={stylesDark.icon} />
                </DropdownToggle>
                <DropdownMenu right className="dropdown-menu justify-content-center align-items-center">
                  <Row>
                  <Button disabled color="transparent" className="ml-3" style={stylesDark.title} >
                    {iconDropDown}
                    <span className="align-middle">{titleDropDown}</span>
                  </Button>
                  <Switch
                    className="mt-3"
                    value={value}
                    onChange={() => { onHandleTheme(); onHandleOnOff() }} style={styles.switch}
                  />
                  </Row>
                  <Button disabled color="transparent" style={stylesDark.title}>
                    <Icon.Sidebar size={14} className="mr-2" />
                    <span className="align-middle">Sidebar Mode</span>
                  </Button>
                </DropdownMenu>
              </Dropdown>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
    // <div style={stylesDark.container} className="">
    //   <Navbar inverse expand="md" style={stylesDark.navbar} className="navbar">
    //     <NavbarBrand href="/" style={stylesDark.brand}>
    //       <img src={ICCopyCake} />
    //       copycake
    //     </NavbarBrand>
    //     {/* <NavItem className="navicon" style={{ marginLeft: '100px' }}>
    //       <Button className="btnIcon" color="transparent" outline style={stylesDark.title} onClick={onHandleToggle}>
    //         {icon}
    //       </Button>
    //     </NavItem> */}
    //     <NavbarToggler onClick={() => { }} />
    //     <Collapse isOpen={() => { }} navbar>
    //       <Nav className="ml-auto" navbar>
    //         <NavItem className="navitems p-1">
    //           <Button color="transparent" style={stylesDark.title} onClick={onHandleExport}>Ekspor</Button>
    //         </NavItem>
    //         <NavItem className="navitems p-1">
    //           <Button color="transparent" style={stylesDark.title} onClick={onHandleSave}>Simpan</Button>
    //         </NavItem>
    //         <NavItem className="navitems p-1">
    //           <Button color="transparent" style={stylesDark.title} onClick={onHandleLogin}>Masuk</Button>
    //         </NavItem>
    //         <NavItem className="navitems-in p-1">
    //           <Button color="transparent" style={stylesDark.title} onClick={onHandleSignUp}>Daftar</Button>
    //         </NavItem>
    //         <NavItem className="p-1">
    //           <Dropdown isOpen={dropdownOpen} toggle={toggle} >
    //             <DropdownToggle style={{ backgroundColor: colors.background.secondary, border: 0 }}>
    //               <Icon.MoreHorizontal size={20} color={colors.icon.secondary} style={stylesDark.icon} />
    //             </DropdownToggle>
    //             <DropdownMenu right className="dropdown-menu justify-content-center align-items-center">
    //               <Button disabled color="transparent" style={stylesDark.title} >
    //                 {iconDropDown}
    //                 <span className="align-middle">{titleDropDown}</span>
    //               </Button>
    //               <Switch
    //                 className="mt-2"
    //                 value={value}
    //                 onChange={() => { onHandleTheme(); onHandleOnOff() }} style={styles.switch}
    //               />
    //               <Button disabled color="transparent" style={stylesDark.title}>
    //                 <Icon.Sidebar size={14} className="mr-2" />
    //                 <span className="align-middle">Sidebar Mode</span>
    //               </Button>
    //             </DropdownMenu>
    //           </Dropdown>
    //         </NavItem>
    //       </Nav>
    //     </Collapse>
    //   </Navbar>
    // </div>
  )
}

const styles = {
  container: {
    left: '0px',
    position: 'fixed',
    right: '0px',
    top: '0px',
    zIndex: 1000,
  },
  img: {
    maxWidth: '22px',
    maxHeight: '24px',
  },
  navbar: {
    backgroundColor: colors.background.secondary,
    paddingLeft: 20,
    height: 60
  },
  brand: {
    color: '#FFFFFF',
    fontWeight: 'bold'
  },
  title: {
    color: colors.text.tertiary,
    fontWeight: '500',
    fontSize: 14
  }
}

export default NavbarComponent