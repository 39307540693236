import React from 'react'
import {
  Row, Card, Col
} from 'reactstrap';
import { stylesDark, stylesLight } from './styles'
import './styles.css'

const List = ({ theme, type, color, onClickCopy, onClickSelect, sm, dangerouslySetInnerHTML }) => {
  if (theme === 'light') {
    return (
      <Col sm={sm} style={stylesLight.column}>
        <Card body outline color={color} style={type === 'secondary' ? stylesLight.cardSecondary : type === 'tertiary' ? stylesLight.cardTertiary : stylesLight.card}>
          <div className="mb-1">
            <Row>
              <div className="pl-2" style={stylesLight.tag}>
                <p className="btn btn-sm mr-1 transparent" style={stylesLight.titleTag}>Landing Page</p>
              </div>
              <div className="pr-2 ml-auto" style={stylesLight.wrapperButtonBottom}>
                <button className="btn btn-sm" style={stylesLight.button} onClick={onClickCopy}>Copy</button>
                <button className="btn btn-sm mx-1" style={stylesLight.button} onClick={onClickSelect}>Select</button>
              </div>
            </Row>
          </div>
          <div className="pb-1" style={stylesLight.wrapperTitle}>
            <p className="titleContent" dangerouslySetInnerHTML={dangerouslySetInnerHTML}></p>
          </div>
        </Card>
      </Col>
    )
  }
  return (
    <Col sm={sm} style={stylesDark.column}>
      <Card body outline color={color} style={type === 'secondary' ? stylesDark.cardSecondary : type === 'tertiary' ? stylesDark.cardTertiary : stylesDark.card}>
        <div className="mb-1">
          <Row>
            <div className="pl-2" style={stylesDark.tag}>
              <p className="btn btn-sm mr-1 transparent" style={stylesDark.titleTag}>Landing Page</p>
            </div>
            <div className="pr-2 ml-auto" style={stylesDark.wrapperButtonBottom}>
              <button className="btn btn-sm" style={stylesDark.button} onClick={onClickCopy}>Copy</button>
              <button className="btn btn-sm mx-1" style={stylesDark.button} onClick={onClickSelect}>Select</button>
            </div>
          </Row>
        </div>
        <div className="pb-1" style={stylesDark.wrapperTitle}>
          <p className="titleContent" dangerouslySetInnerHTML={dangerouslySetInnerHTML}></p>
        </div>
      </Card>
    </Col>
  )
}

export default List
