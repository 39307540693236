const initialState = {
  form: {
    jenisProduk: '',
    targetPengguna: '',
    hasil: '',
    problem: '',
    solusi: '',
    tokohAhli: '',
    produk: '',
  },
  datas: [
    {
      id: 1,
      label: 'What is your product?',
      placeholder: 'Copyfast, Skecth',
    },
    {
      id: 2,
      label: 'What does your audience want?',
      placeholder: 'Higher Convension Rate, Bigger Muscles',
    },
    {
      id: 3,
      label: 'What solution do you offer?',
      placeholder: 'Copyfast, Headlime',
    },
    {
      id: 4,
      label: 'What is your product?',
      placeholder: 'Copyfast, Adobe',
    },
    {
      id: 5,
      label: 'What does your audience want?',
      placeholder: 'Higher Convention Rate, Bigger Muscles',
    },
    {
      id: 6,
      label: 'What solution do you offer',
      placeholder: 'Headlime, Sketch',
    },
  ]
}

export const OnBoardingReducer = (state = initialState, action) => {
  if (action.type === 'SET_FORM') {
    return {
      ...state,
      form: {
        ...state.form,
        [action.inputType]: action.inputValue,
      }
    }
  }
  return state;
}