import ICCopyCake from './copycake.png'
import ICCopyCakeBlack from './copycakeblack.png'
import ICToggle from './toggle.svg'
import ICDot from './dot.svg'
import ICHeadline from './headline.png'
import ICSubheadline from './subheadline.png'
import ICDeskripsi from './deskripsi.png'
import ICVariable from './variable.svg'
import ICBenefit from './benefit.png'
import ICWhatsapp from './whatsapp.png'
import ICList from './list.png'
import ICListMd from './list-md.png'
import ICGrid from './grid.png'

export {ICCopyCake, ICCopyCakeBlack, ICToggle, ICDot, ICHeadline, ICSubheadline, ICDeskripsi, ICVariable,ICBenefit, ICWhatsapp, ICList, ICListMd, ICGrid}