import { colors } from "../../../utils";

export const styles = {
  page: {
    backgroundColor: colors.background.primary,
    marginTop: 60
  },
  title: {
    fontWeight: '500',
    fontSize: '24px'
  },
  column: {
    justifyContent: 'space-around',
    paddingBottom: '1rem'
  },
  card: {
    backgroundColor: colors.background.primary,
    height: '164px',
    width: '100%',
    borderColor: colors.border.secondary,
    borderWidth: 1,
  },
  tag: {
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: colors.border.secondary
  },
  titleTag: {
    color: colors.text.quaternary,
    fontWeight: '400',
    fontSize: '12px',
    borderWidth: 1,
    borderColor: colors.border.secondary
  },
  wrapperTitle: {
    color: '#FFFFFF',
    fontSize: 16,
    height: 120,
    alignItems: 'center'
  },
  titleCard: {
  },
  button: {
    color: '#FFFFFF',
    fontSize: '12px',
  },
  container: {
    zIndex: 999,
    overflowY: 'auto',
    height: '100%',
    marginBottom: 200,
    backgroundColor: colors.background.primary,
  },
  sidebar: {
    marginBottom: '40%',
  },
  label: {
    fontSize: '12px',
    color: colors.text.tertiary,
    fontWeight: '500',
    fontFamily: 'Montserrat'
  },
  iconCode: {
    marginRight: '7px'
  },
  icon: {
    marginLeft: 'auto'
  },
  inputTop: {
    borderColor: colors.border.secondary,
    borderWidth: '1px',
    backgroundColor: colors.background.primary,
    color: colors.text.quinary
  },
  input: {
    backgroundColor: colors.background.secondary,
    color: colors.text.quaternary,
    fontSize: '14px',
    fontWeight: '400',
    borderWidth: 0,
  },
  inputUrutkan: {
    backgroundColor: colors.background.primary, 
    border: 0, 
    color:  colors.text.tertiary,
    fontSize: '14px'
  },
  buttonExample: {
    color: colors.text.tertiary,
    fontSize: '12px',
    fontWeight: '500'
  }
}