/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import './styles.css'
import { IMonBOardingFourth } from '../../assets'
import { Link } from 'react-router-dom'

const OnBoardingFourth = () => {
  return (
    <div class="page">
      <div class="container">
        <h1 class="title">Awesome! Now something to know before starting.</h1>
        <p class="subtitle">A few key functions of Headlime to get familiar with.</p>
        <img src={IMonBOardingFourth} />
        <div class="wrapper-button">
          <button class="btn-prev"><Link to='/third'>Previous</Link></button>
          <button class="btn-next p-2 px-4 rounded"><Link to='/home'>Finish</Link></button>
        </div>
      </div>
    </div>
  )
}

export default OnBoardingFourth