/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import './styles.css'
import { IMonBoardingThird } from '../../assets'
import { Link } from 'react-router-dom'

const OnBoardingThird = () => {
  return (
    <div class="page">
      <div class="container">
        <h1 class="title">The action menu</h1>
        <p class="subtitle">A few key function oh Headlime to get familiar with</p>
        <img src={IMonBoardingThird} />
        <div class="wrapper-button">
          <button class="btn-prev"><Link to="/second">Previous</Link></button>
          <button class="btn-next p-2 px-4 rounded"><Link to="/fourth">Next Step</Link></button>
        </div>
      </div>
    </div>
  )
}

export default OnBoardingThird