import { colors } from "../../../utils";

export const stylesDark = {
  page: {
    backgroundColor: colors.background.primary,
    marginTop: 60
  },
  title: {
    fontWeight: '500',
    fontSize: '24px'
  },
  column: {
    justifyContent: 'space-around',
    paddingBottom: '1rem'
  },
  cardList: {
    backgroundColor: colors.background.primary,
    width: '100%',
    borderColor: colors.border.secondary,
    borderWidth: 1,
  },
  tag: {
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: colors.border.secondary
  },
  titleTag: {
    color: colors.text.quaternary,
    fontWeight: '400',
    fontSize: '12px',
    borderWidth: 1,
    borderColor: colors.border.secondary
  },
  wrapperTitle: {
    color: '#FFFFFF',
    fontSize: '16px',
    // height: 120,
    alignItems: 'center'
  },
  titleCard: {
  },
  button: {
    color: '#FFFFFF',
    fontSize: '12px',
  },
}

export const stylesLight = {
  cardList: {
    backgroundColor: colors.background.tertiary,
    width: '100%',
    borderColor: colors.border.tertiary,
    borderWidth: 1,
  },
  tag: {
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: colors.border.secondary
  },
  titleTag: {
    color: colors.text.quaternary,
    fontWeight: '400',
    fontSize: '12px',
    borderWidth: 1,
    borderColor: colors.border.secondary
  },
  wrapperTitle: {
    color: '#FFFFFF',
    fontSize: '16px',
    // height: 120,
    alignItems: 'center'
  },
  titleCard: {
    color: colors.text.senary
  },
  button: {
    color: colors.text.senary,
    fontSize: '12px',
    borderWidth: 1,
    borderColor: colors.border.tertiary
  },
  }