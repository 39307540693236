import { colors } from "../../../utils";


export const stylesDark = {
  column: {
    justifyContent: 'space-around',
    paddingBottom: '1rem'
  },
  cardList: {
    backgroundColor: colors.background.primary,
    width: '100%',
    height: '100%',
    borderColor: colors.border.secondary,
    borderWidth: 1,
  },
  card: {
    backgroundColor: colors.background.primary,
    padding: '10px',
    paddingBottom: '15px',
    borderColor: colors.border.secondary,
    borderWidth: 1,
  },
  cardSecondary: {
    backgroundColor: colors.background.primary,
    padding: '10px',
    paddingBottom: '60px',
    borderColor: colors.border.secondary,
    borderWidth: 1,
  },
  cardTertiary: {
    backgroundColor: colors.background.primary,
    padding: '10px',
    height: '350px',
    borderColor: colors.border.secondary,
    borderWidth: 1,
  },
  tag: {
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: colors.border.secondary
  },
  titleTag: {
    color: colors.text.quaternary,
    fontWeight: '400',
    fontSize: '12px',
    borderWidth: 1,
    borderColor: colors.border.secondary
  },
  wrapperTitle: {
    color: '#FFFFFF',
    // fontSize: '16px',
    height: '120px',
    alignItems: 'center'
  },
  // titleCard: {
  //   fontSize: '150px'
  // },
  button: {
    color: '#FFFFFF',
    fontSize: '12px',
  },
}

export const stylesLight = {
    column: {
      justifyContent: 'space-around',
      paddingBottom: '1rem',
    },
    cardList: {
      backgroundColor: colors.background.tertiary,
      height: '100%',
      width: '100%',
    },
    card: {
      backgroundColor: '#FFFFFF',
      padding: '10px',
      paddingBottom: '15px',
      borderColor: colors.border.tertiary,
      borderWidth: 1,
    },
    cardSecondary: {
      backgroundColor: '#FFFFFF',
      padding: '10px',
      paddingBottom: '60px',
      borderColor: colors.border.tertiary,
      borderWidth: 1,
    },
    cardTertiary: {
      backgroundColor: '#FFFFFF',
      padding: '10px',
      height: '350px',
      borderColor: colors.border.tertiary,
      borderWidth: 1,
    },
    tag: {
      justifyContent: 'center',
      borderWidth: 1,
      borderColor: colors.border.primary
    },
    titleTag: {
      color: colors.text.quaternary,
      fontWeight: '400',
      fontSize: '12px',
      borderWidth: 1,
      borderColor: colors.border.primary
    },
    wrapperTitle: {
      color: colors.text.senary,
      // fontSize: '16px',
      height: '120px',
      alignItems: 'center'
    },
    // titleCard: {
    //   color: colors.text.senary,
    //   fontSize: '15px'
    // },
    button: {
      color: colors.text.quaternary,
      fontSize: '12px',
    },
  }