const initialStateRegister = {
  form: {
    fullName: '',
    email: '',
    password: ''
  },
}

export const RegisterReducer = (state = initialStateRegister, action) => {
  if (action.type === 'SET_FORM') {
    return {
      ...state,
      form: {
        ...state.form,
        [action.inputType]: action.inputValue,
      }
    }
  }
  return state;
}